import React, { ReactElement } from 'react';
import { Check } from 'react-feather';
import { components } from 'react-select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Option = (props: any): ReactElement => (
  <components.Option {...props}>
    <div className="flex flex-col text-gray-lightmode-400">
      {props.data.label}
      {props.data.subtitle && (
        <div className="mt-1 text-gray-lightmode-300 text-xs">
          {props.data.subtitle}
        </div>
      )}
    </div>
    {props.isSelected && <Check className="ml-auto" />}
  </components.Option>
);
