import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { CopyInput } from 'components/input';
import { Link } from 'components/link';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const SimpleSamlCreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <>
    <Text>
      Follow the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://simplesamlphp.org/docs/latest/simplesamlphp-idp.html"
      >
        SimpleSAMLphp documentation
      </Link>{' '}
      to set up SimpleSAMLphp as an Identity Provider and add a new Service
      Provider.
    </Text>

    <Text>
      Copy and paste the ACS URL and SP Entity ID into the corresponding fields
      for Service Provider configuration. You can find more on how to structure
      this under "Adding SPs to the IdP" in the SimpleSAMLphp documentation
      linked above.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Text>
      The necessary SP metadata can also be found in the SP metadata URL
      provided below.
    </Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Confirm
      label="I've created and configured a SAML application."
      onClick={onNextStep}
    />
  </>
);

export const SimpleSamlUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://mysimplesamlphpserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mysimplesamlphpserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      Obtain the IdP Metadata URL. As noted in the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://simplesamlphp.org/docs/latest/simplesamlphp-idp.html"
      >
        "Adding this IdP to other SPs" section of the SimpleSAMLphp
        documentation
      </Link>
      , the IdP metadata URL should be available from /saml2/idp/metadata.php
    </Text>

    <Text>
      Alternatively, you can manually configure the connection by providing the
      IdP URI (Entity ID), IdP SSO URL and X.509 Certificate.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>
  </MetadataUrlStep>
);

export const SimpleSamlConfigureClaims: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <>
    <Text>
      You will need to send the following 4 required attributes in the SAML
      Response: id, email, firstName and lastName.
    </Text>

    <Text>Ensure the following attribute mapping is set:</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML claims."
      onClick={onNextStep}
    />
  </>
);
